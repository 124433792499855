import axios from "axios";
import history from "../history";
import {
  ERROR_MESSAGE,
  LOADED,
  LOADING,
  SUCCESS_MESSAGE,
  VALIDATION_ERROR,
} from "../reducers/type";
import api from "./apis/index";

export const getSupport = (p, t, s, u_s, m) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    let response = null;
    // if(ds && ds){
    //   response = await api(localStorage.getItem("token")).get(`/message-management/ticket?p=${p?p:1}&t=${t?t:""}&s=${s?s:""}&u_s=${u_s?u_s:""}&ds=${ds?ds:""}&de=${de?de:""}&e=${m?m:""}`);
    //   console.log(response)
    // }

    response = await api(localStorage.getItem("token")).get(
      `/message-management/ticket?p=${p ? p : 1}&t=${t ? t : ""}&s=${
        s ? s : ""
      }&u_s=${u_s ? u_s : ""}&m=${m ? m : ""}`
    );

    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data;
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if (err.message == "Network Error") {
      history.push("/networkError");
      return;
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const getSupportDetails = (ticket) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/message-management/ticket/detail?ticket=${ticket}`
    );
    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data.ticket;
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if (err.message == "Network Error") {
      history.push("/networkError");
      return;
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const supportReply = (data, ticket) => async (dispatch) => {
  dispatch({ type: LOADING });
  let file = null;
  if (data.file !== undefined)
    if (data.file.fileList.length > 0) {
      Object.keys(data).map((ele) => {
        if (typeof data[ele] === "object") {
          Object.keys(data[ele]).map((last, id) => {
            if (last === "fileList") {
              file = data[ele][last][0].response.name;
            }
          });
        }
      });
    }
  try {
    let obj = null;
    if (data?.file?.fileList?.length > 0) {
      obj = {
        ticket: ticket,
        message: data.text,
        file: file,
      };
    } else {
      obj = {
        ticket: ticket,
        message: data.text,
      };
    }
    const response = await api(localStorage.getItem("token")).patch(
      "message-management/ticket/reply",
      { ...obj }
    );

    if (response.status === 201) {
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Reply is successfully send!",
      });
      dispatch({ type: LOADED });
      return response.status;
    }
  } catch (err) {
    // console.log(err)
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if (err.message == "Network Error") {
      history.push("/networkError");
      return;
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const supportFileDownloader = (name) => async (dispatch) => {
  dispatch({ type: LOADING });
  const ext = name.split(".")[1];

  try {
    const response = await axios(
      `https://api-delivery.redorch.com/archon/v1/message-management/ticket/file/download?file_name=${name}`,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        method: "GET",
        responseType: "blob",
      }
    );

    if (response.status === 200) {
      // FileDown
      dispatch({ type: LOADED });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `FileName.${ext}`);
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if (err.message == "Network Error") {
      history.push("/networkError");
      return;
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const closeTicket = (ticket, remark) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const obj = {
      ticket: ticket,
      remark: remark.remark,
    };
    const response = await api(localStorage.getItem("token")).patch(
      "message-management/ticket/closed",
      { ...obj }
    );

    if (response.status === 201) {
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Reply is successfully closed!",
      });
      dispatch({ type: LOADED });
      return response.status;
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if (err.message == "Network Error") {
      history.push("/networkError");
      return;
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const SendNotification = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const obj = {
      email: data.email,
      message: data.message,
    };

    const response = await api(localStorage.getItem("token")).post(
      "message-management/notification/send",
      { ...obj }
    );

    if (response.status === 201) {
      dispatch({ type: SUCCESS_MESSAGE, payload: "Email successfully send!" });
      dispatch({ type: LOADED });
      return response.status;
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if (err.message == "Network Error") {
      history.push("/networkError");
      return;
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};
