import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import "./index.css";
import { Layout, Menu } from "antd";
import {
  BankOutlined,
  BarChartOutlined,
  BorderlessTableOutlined,
  CarOutlined,
  ClusterOutlined,
  CodeSandboxOutlined,
  CommentOutlined,
  DeliveredProcedureOutlined,
  DollarCircleOutlined,
  DollarOutlined,
  DotChartOutlined,
  DribbbleSquareOutlined,
  DropboxOutlined,
  EnvironmentOutlined,
  EuroCircleOutlined,
  FilterOutlined,
  FontSizeOutlined,
  GlobalOutlined,
  HomeOutlined,
  LineChartOutlined,
  MediumOutlined,
  MessageOutlined,
  MonitorOutlined,
  NotificationOutlined,
  OrderedListOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  PlusSquareOutlined,
  RocketOutlined,
  SearchOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  ShopTwoTone,
  SoundOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  UserSwitchOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { SpinnerRoundOutlined } from "spinners-react";
const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [access, setAccess] = useState(null);

  useEffect(async () => {
    window.scrollTo(0, 0);
    setAccess(JSON.parse(localStorage.getItem("access")));
  }, []);

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };
  {
    /* <ul class="ant-menu ant-menu-light ant-menu-inline-collapsed ant-menu-root ant-menu-vertical" role="menu"/> */
  }
  return (
   
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      width="280px"
      breakpoint="sm"
      collapsedWidth="80"
      style={{
        backgroundColor: "white",
        boxShadow: "4px 3px 8px 0px rgba(0,0,0,0.10)",
      }}
    >
      <Menu theme="light" defaultSelectedKeys={["1"]} mode="inline">
        
        <Menu.Item key="dashboard" icon={<HomeOutlined />}>
          <Link to="/dashboard">Dashboard</Link>
        </Menu.Item>
        
        {/* Admin_management */}
        {access?.user_management ? (
          <SubMenu
            key="Admin-management"
            icon={<UserOutlined />}
            title="Admin Management"
          >
            <Menu.Item key="role" icon={<UserAddOutlined />}>
              <Link to="/rolelist">Role</Link>
            </Menu.Item>
            <Menu.Item key="user" icon={<UsergroupAddOutlined />}>
              <Link to="/userlist">User</Link>
            </Menu.Item>
          </SubMenu>
        ) : null}
        {/* area and Price management */}
        {access?.area_pricing_management ? (
          <SubMenu
            key="Area And Price-management"
            icon={<GlobalOutlined />}
            title="Area And Price Management"
          >
            <Menu.Item key="price_model" icon={<EuroCircleOutlined />}>
              <Link to="/pricie-model">Pricing</Link>
            </Menu.Item>
            <Menu.Item key="add_price" icon={<DollarCircleOutlined />}>
              <Link to="/pricie-add">Add Price</Link>
            </Menu.Item>
            <Menu.Item key="area_model" icon={<EnvironmentOutlined />}>
              <Link to="/area-model">Area Model</Link>
            </Menu.Item>
            <Menu.Item key="add_new_area" icon={<PlusSquareOutlined />}>
              <Link to="/add-area-model">Add New Area</Link>
            </Menu.Item>
          </SubMenu>
        ) : null}
        {/* Zone */}
        {access?.area_pricing_management ? (
          <SubMenu key="Zone" icon={<EnvironmentOutlined />} title="Zone">
            <Menu.Item key="zone" icon={<EnvironmentOutlined />}>
              <Link to="/zonelist">Zone List</Link>
            </Menu.Item>
          </SubMenu>
        ) : null}
        {/* Marchant management */}
        {access?.merchant_management ? (
          <SubMenu
            key="Merchant-management"
            icon={<TeamOutlined />}
            title="Merchant Management"
          >
            <Menu.Item key="merchant_statistic" icon={<MediumOutlined />}>
              <Link to="/merchant-dashboard">Merchant Statistic</Link>
            </Menu.Item>
            <Menu.Item key="top_merchant" icon={<VerticalAlignTopOutlined/>}>
              <Link to="/top-merchants">Top Merchant</Link>
            </Menu.Item>
            <Menu.Item key="merchant_list" icon={<UserSwitchOutlined />}>
              <Link to="/merchant-list">Merchant List</Link>
            </Menu.Item>
            <Menu.Item key="merchanrt_create" icon={<UserAddOutlined />}>
              <Link to="/create-merchant">Merchant Create</Link>
            </Menu.Item>

            <Menu.Item key="shop_create" icon={<ShopOutlined />}>
              <Link to="/create-shop"> Shop Create</Link>
            </Menu.Item>
            <Menu.Item key="shop_list" icon={<ShoppingCartOutlined />}>
              <Link to="/shop-list">Shop List</Link>
            </Menu.Item>
            <Menu.Item key="unauth_shop" icon={<ShopTwoTone />}>
              <Link to="/unauthshop-list">Unauthanticate Shop List</Link>
            </Menu.Item>
          </SubMenu>
        ) : null}
        {/* Parcel management */}
        {access?.order_management ? (
          <SubMenu key="Parcel" icon={<DropboxOutlined />} title="Parcel">
            <Menu.Item key="parcel_dashboard" icon={<BarChartOutlined />}>
              <Link to="/parcel-dashboard">Parcel Dashboard</Link>
            </Menu.Item>
            <Menu.Item key="parcel_search" icon={<MonitorOutlined />}>
              <Link to="/search-parcel">Parcel Search</Link>
            </Menu.Item>

            <Menu.Item key="parcel_create" icon={<PlusSquareOutlined />}>
              <Link to="/create-parcel">Parcel Create</Link>
             
             
            </Menu.Item>
            <Menu.Item key="bulk_create" icon={<PlusCircleOutlined />}>
            <Link to="/create-bulk">Bulk Create</Link>
            </Menu.Item>
            <Menu.Item key="parcel_custom_create" icon={<PlusOutlined />}>
            <Link to="/create-custom-parcel">Create Custom Parcel</Link> 
            </Menu.Item>
            <Menu.Item key="parcel_deliver" icon={<RocketOutlined />}>
              <Link to="/delivery-parcel">Parcel Delivery</Link>
            </Menu.Item>
          </SubMenu>
        ) : null}
        {/* Deliver Man */}
        {access?.driver_management ? (
          <SubMenu
            key="delivery_man"
            icon={<DeliveredProcedureOutlined />}
            title="Delivery Man"
          >
            <Menu.Item key="driver_statistic" icon={<LineChartOutlined />}>
              <Link to="/driver-dashboard">Driver Statistic</Link>
            </Menu.Item>
            <Menu.Item key="driver_list" icon={<OrderedListOutlined />}>
              <Link to="/driver-list"> Driver List</Link>
             
            </Menu.Item>
            <Menu.Item key="driver_map" icon={<DribbbleSquareOutlined />}>
            <Link to="/driver-map"> Driver Map</Link> 
            </Menu.Item>
            <Menu.Item key="driver_create" icon={<PlusCircleOutlined />}>
              <Link to="/create-driver"> Driver Create</Link>
            </Menu.Item>
          </SubMenu>
        ) : null}
        {/* WareHouse  */}
        {access?.warehouse_management ? (
          <SubMenu
            key="warehouse"
            icon={<CodeSandboxOutlined />}
            title="Warehouse"
          >
            <Menu.Item key="warehouse_statistic" icon={<DotChartOutlined />}>
              <Link to="/warehouse-dashboard">Warehouse Statistic</Link>
            </Menu.Item>
            <Menu.Item key="warehouse_list" icon={<UnorderedListOutlined />}>
              <Link to="/warehouse-list">Warehouse List</Link>
            </Menu.Item>
            <Menu.Item key="warehouse_create" icon={<PlusCircleOutlined />}>
              <Link to="/warehouse-create">Warehouse Create</Link>
            </Menu.Item>
            <Menu.Item
              key="create_warehouse_Manager"
              icon={<PlusSquareOutlined />}
            >
              <Link to="/warehouse-manager-create">
                Warehouse Manager Create
              </Link>
            </Menu.Item>
            <Menu.Item
              key="warehouse_manager_list"
              icon={<BorderlessTableOutlined />}
            >
              <Link to="/warehouse-manager-list">Warehouse Manager List</Link>
            </Menu.Item>
          </SubMenu>
        ) : null}
        {/* Finance  */}
        {access?.finance_management ? (
          <SubMenu key="Finance" icon={<BankOutlined />} title="Finance">
            <SubMenu key="balance" icon={<DollarOutlined />} title="Transactions">
              <Menu.Item key="withdraw_balance">
                {" "}
                <Link to="/withdraw-balance">Withdraw</Link>
               
              
              </Menu.Item>
              <Menu.Item key="deposit_balance">  <Link to="/deposit-balance">Deposit</Link></Menu.Item>
              <Menu.Item key="inter_transfer">  <Link to="/intra-transfer-balance">Inter transfer</Link></Menu.Item>
            
              <Menu.Item key="total_transaction">
                <Link to="/total-transaction">Total Transaction</Link>
              </Menu.Item>
              <Menu.Item key="Faild_transaction">
                <Link to="/failed-transaction">Failed Transaction</Link>
              </Menu.Item>
              <Menu.Item key="8">
                <Link to="/merchant-transaction-report">
                  Merchant Transaction Report
                </Link>
              </Menu.Item>
            </SubMenu>

            <SubMenu key="Demo" icon={<CarOutlined />} title="Demo">
              <Menu.Item key="unpaid_vat">
                {" "}
                <Link to="/unpaid-vat">Unpaid VAT </Link>
              </Menu.Item>
              <Menu.Item key="bahon_details">
                <Link to="/bahon-details">Demo Details</Link>
              </Menu.Item>
            </SubMenu>

            <Menu.Item key="loan" icon={<BankOutlined />}>
              <Link to="/loan-management">Loan</Link>
            </Menu.Item>
            <Menu.Item key="warehouse1" icon={<DropboxOutlined />}>
              <Link to="/all-warehouse">Warehouse</Link>
            </Menu.Item>
            <Menu.Item key="search_house" icon={<SearchOutlined />}>
              <Link to="/search-merchant">Search Merchant</Link>
            </Menu.Item>
            <Menu.Item key="recivable_shop" icon={<ShoppingCartOutlined />}>
              <Link to="/receivable-shop">Receivable Shop</Link>
            </Menu.Item>
            <Menu.Item key="paybable_shop" icon={<ShoppingOutlined />}>
              <Link to="/payable-shop">Payable Shop</Link>
            </Menu.Item>
            <Menu.Item key="search_driver" icon={<MonitorOutlined />}>
              <Link to="/search-driver"> Search Driver</Link>
            </Menu.Item>
            <Menu.Item key="payable_driver" icon={<ClusterOutlined />}>
              <Link to="/payable-driver">Payable Driver</Link>
            </Menu.Item>
          </SubMenu>
        ) : null}
        {/* message menagement */}
        {access?.message_management ? (
          <SubMenu key="Message" icon={<CommentOutlined />} title="Support Management">
            <Menu.Item key="message_create" icon={<MessageOutlined />}>
              <Link to="/createmessage">Message Create</Link>
             
            </Menu.Item>
            <Menu.Item key="merchant_message" icon={<SoundOutlined /> }>
              <Link to="/merchant-message">Merchant Notification</Link>
             
            </Menu.Item>
            
            <Menu.Item key="notice_list" icon={<NotificationOutlined/>}>
              
              <Link to="/notice-list">Notice</Link>
            </Menu.Item>
            <Menu.Item key="tickets" icon={<FontSizeOutlined />}>
              
              <Link to="/ticket-list">Tickets</Link>
            </Menu.Item>
            {/* <Menu.Item key="response" icon={<NotificationOutlined />}>
              
              <Link to="/response-list">Responses</Link>
            </Menu.Item> */}
          </SubMenu>
        ) : null}
      </Menu>
    </Sider>
    
  );
};

export default Sidebar;
