import "./App.css";
import PageLayout from "./root/PageLayout";
import history from "./history";
import WrongPage from "./components/wrongPage";
import MessageAlert from "./components/messageAlert";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Login from "./root/Login";
import GlobalStyle from "./GlobalStyle";
import { connect } from "react-redux";
function App(props) {
  // console.log(props)
  return (
    <>
    {props.authSuccess ? <MessageAlert type="success" value={props.authSuccess} /> : null}
      {props.authError ? <MessageAlert type="error" value={props.authError} /> : null}
      {props.authValidationError ? 
        props.authValidationError.map((item,id )=> (
          <MessageAlert type="error" value={item.message} duration={item?.duration} />
        ))
        :
        null
      }
    <GlobalStyle/>
      <Router history={history}>
        <Switch>
          <Route path="/opps" component={WrongPage} />
          <Route path="/login" component={Login} />
          <Route path="/" component={PageLayout} />
          <Redirect to="/opps" />
        </Switch>
      </Router>
    </>
  );
}

const mapStateToProps = (state) => {
  // console.log(state)
  return {
    authSuccess: state.message.authSuccess || state.message.success,
    authError: state.message.authError || state.message.error,
    authValidationError: state.message.authValidationError ||state.message.validationError,
  };
};
export default connect(mapStateToProps)(App);
