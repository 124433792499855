import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, PageHeader, Table, Tag, Card, Col, Row, Divider } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RecentMerchants, MerchatStatistic,TopTenMerchants } from "../../../actions/merchant";
import { RecentShops, ShopStatistics } from "../../../actions/shop";
import { Column, Line } from "@ant-design/plots";


const MerchantStatistic = ({
  RecentMerchants,
  MerchatStatistic,
  RecentShops,
  ShopStatistics,
  TopTenMerchants
}) => {
  const [recentmer, setRecentMer] = useState([]);
  const [datashop, setDataShop] = useState([]);
  const [merchantStatistic, setMerchantStatistic] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [shopStatistic, setShopStatistic] = useState([]);
  const [data3,setData3]=useState([]);
  const [TTM,setTopTenMerChant]=useState([]);

  useEffect(async () => {
    window.scrollTo(0, 0)
    const resmerchantr = await RecentMerchants();
    const resshopp = await RecentShops();
    const resmerstatistic = await MerchatStatistic();
    const resshopstatistic = await ShopStatistics();
    const TTMerchant = await TopTenMerchants();
    setTopTenMerChant(TTMerchant)
    

    setRecentMer(resmerchantr);
    setDataShop(resshopp);
    setData1(resmerstatistic?.lastYearSummery);
    setMerchantStatistic(resmerstatistic?.merchantCount[0]);

    setData2(resshopstatistic?.lastYearSummery);
    setShopStatistic(resshopstatistic?.shopCount[0]);

    let lintChartData = []

    for (let i=0; i < resmerstatistic?.lastYearSummery?.length ; i++ ){

      lintChartData.push({
        name: "Merchant",
        count: resmerstatistic?.lastYearSummery[i]?.total,
        date: resmerstatistic?.lastYearSummery[i]?._id
      })

    }

    for (let i=0; i < resshopstatistic?.lastYearSummery?.length ; i++ ){

      lintChartData.push({
        name: "Shop",
        count: resshopstatistic?.lastYearSummery[i].total,
        date: resshopstatistic?.lastYearSummery[i]._id
      })

    }

    setData3(lintChartData);



  
  }, []);

  const searchColumns = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
            style={{ marginBottom: 8, display: "block" }}
          ></Input>
          <Button
            onClick={() => {
              confirm();
            }}
            type="primary"
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          &nbsp;
          <Button
            onClick={() => {
              clearFilters();
            }}
            size="small"
            type="danger"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: () => {
      return <SearchOutlined />;
    },
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
  });
  const columns = [
    {
      title: "Merchant Name",
      dataIndex: "name",
      key: "name",
      width: "15%",
      ...searchColumns("name"),
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
      width: "15%",
      ...searchColumns("mobile_number"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "15%",
      ...searchColumns("email"),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: "25%",
      ...searchColumns("address"),
    },
    {
      title: "Shop",
      dataIndex: "shop_count",
      key: "shop_count",
      width: "10%",
      ...searchColumns("shop_count"),
    },
    {
      title: "Status",
      dataIndex: "authenticated",
      key: "authenticated",
      width: "15%",
    },
  ];
  const createData = (
    name,
    mobile_number,
    email,
    address,
    shop_count,
    authenticated
  ) => {
    return { name, mobile_number, email, address, shop_count, authenticated };
  };

  const rows = [
    recentmer
      ? recentmer?.map((merchant_recent) =>
          createData(
            merchant_recent?.name,
            merchant_recent?.mobile_number,
            merchant_recent?.email,
            merchant_recent?.address,
            merchant_recent?.shop_count,
            !merchant_recent?.authenticated === true ? (
              <Tag color="#f01e1e">Unauthenticated</Tag>
            ) : (
              <Tag color="#2df893">Authenticated</Tag>
            )
          )
        )
      : "",
  ];

  const columnShop = [
    {
      title: "Merchant Name",
      dataIndex: "name",
      key: "name",
      width: "15%",
      ...searchColumns("name"),
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
      width: "15%",
      ...searchColumns("mobile_number"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "15%",
      ...searchColumns("email"),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: "25%",
      ...searchColumns("address"),
    },
    {
      title: "Status",
      dataIndex: "authenticated",
      key: "authenticated",
      width: "15%",
    },
  ];
  const createDataShop = (
    name,
    mobile_number,
    email,
    address,

    authenticated
  ) => {
    return { name, mobile_number, email, address, authenticated };
  };

  const rowShop = [
    datashop
      ? datashop?.map((shop) =>
          createDataShop(
            shop?.name,
            shop?.mobile_number,
            shop?.email,
            shop?.address,

            !shop?.authenticated === true ? (
              <Tag color="#f01e1e">Unauthenticated</Tag>
            ) : (
              <Tag color="#2df893">Authenticated</Tag>
            )
          )
        )
      : "",
  ];

  const columnTopTenMerchant = [
    {
      title: "Merchant Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
      ...searchColumns("name"),
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
      width: "20%",
      ...searchColumns("mobile_number"),
    },
    {
      title: "Shop",
      dataIndex: "email",
      key: "email",
      width: "20%",
      ...searchColumns("email"),
    },
    {
      title: "Total Order",
      dataIndex: "address",
      key: "address",
      width: "20%",
      ...searchColumns("address"),
    },

    
  ];
  const createDataTopTenMerchant = (
    name,
    mobile_number,
    email,
    address,

    authenticated
  ) => {
    return { name, mobile_number, email, address, authenticated };
  };

  const rowTopTenMerchant= [
    TTM
      ? TTM?.data?.map((topten) =>
      createDataTopTenMerchant(
            topten?.merchant,
            topten?.mobile_number,
            topten?.shop,
            topten?.total_order,

            
          )
        )
      : "",
  ];

  const Pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Merchant List"
      subTitle="Merchant Management"
    ></PageHeader>
  );

  const AllTable = (
      <Row gutter={16}>
      <Col xs={12} lg={12} md={24} sm={24} style={{ boxShadow: "0px 3px 12px 0px rgba(0,0,0,0.41)" }}>
       
          <Divider>Recent Merchant</Divider>
          <Table
          
            columns={columns}
            dataSource={rows[0]}
            scroll={{ x: 1000 }}
            sticky
            size="small"
            pagination={false}
            // style={{width:'600px'}}
          />
          
        
      </Col>
      <Col xs={12} lg={12} md={24} sm={24} style={{ boxShadow: "0px 3px 12px 0px rgba(0,0,0,0.41)" }}>
        
          <Divider>Recent Shop</Divider>
          <Table
            columns={columnShop}
            dataSource={rowShop[0]}
            scroll={{ x: 1000 }}
            sticky
            size="small"
            pagination={false}
            // style={{width:'600px'}}
          />
        
      </Col>
    </Row>
  );

  const config = {
    data:data1,
    xField: "_id",
    yField: "total",
    title:{
      visible:true,
      position:"center",
      text:'Recent Merchant Registration'
    },
    label: {
      position: "middle",
      // 'top', 'bottom', 'middle',

      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      total: {
        alias: "merchant",
      },
      _id: {
        alias: "date",
      },
    },
  };

  const config1 = {
    data:data2,
    title:{
      visible:true,
      position:"center",
      text:'Recent Merchant Registration'
    },
    xField: "_id",
    yField: "total",
    title:{
      visible:true,
      text:'Recent Shop Registration'
    },
    label: {
      position: "middle",
      // 'top', 'bottom', 'middle',

      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      total: {
        alias: "Shop",
      },
      _id: {
        alias: "date",
      },
    },
  };
  const Recentshop = (
    <Row gutter={16}>
      <Col xs={12} lg={12} md={24} sm={24} style={{ textAlign: "center" }}>
       <Divider>Merchant</Divider>
        <Column {...config} />
      </Col>
      <Col xs={12} lg={12} md={24} sm={24} style={{ textAlign: "center" }}>
      <Divider>Shop</Divider>
        <Column {...config1} />
      </Col>
    </Row>
  );

  const totalshop = (
    <Row gutter={16}>
      <Col span={6} style={{ textAlign: "center" }}>
        <Card
          title="Merchant in Last 3 Month  "
          style={{
            textAlign: "center",
            backgroundColor: "#3AB569",
            color: "white",
            
          }}
          bordered={false}
        >
          {merchantStatistic?.lastThreeMonth}
        </Card>
      </Col>
      <Col span={6} style={{ textAlign: "center", color: "white" }}>
        <Card
          title="Total Merchant"
          style={{
            textAlign: "center",
            backgroundColor: "#3AB569",
            color: "white",
          }}
          bordered={false}
        >
          {merchantStatistic?.totalMerchant}
        </Card>
      </Col>
      <Col span={6} style={{ textAlign: "center" }}>
        <Card
          title="Shop in Last 3 Month  "
          style={{
            textAlign: "center",
            backgroundColor: "#3AB569",
            color: "white",
          }}
          bordered={false}
        >
          {shopStatistic?.lastThreeMonth}
        </Card>
      </Col>
      <Col span={6} style={{ textAlign: "center", color: "white" }}>
        <Card
          title="Total Shop"
          style={{
            textAlign: "center",
            backgroundColor: "#3AB569",
            color: "white",
          }}
          bordered={false}
        >
          {shopStatistic?.totalShop}
        </Card>
      </Col>
    </Row>
  );

  const config2 = {
    data : data3,
    xField: 'date',
    yField: 'count',
    seriesField: 'name',
    yAxis: {
      label: {
        formatter: (v) => `${v} `,
      },
    },
    legend: {
      position: 'top',
    },
    smooth: false,
   
    animation: {
      appear: {
        animation: 'path-in',
        duration: 5000,
      },
    },
  };
  const Main_chart=(
    <>
    
    <Line {...config2} /><br></br> 
    </>
  )
  const toptenmer=(
    <Row gutter={16}>
      
      <Col xs={24} lg={24} md={24} sm={24} style={{ boxShadow: "0px 3px 12px 0px rgba(0,0,0,0.41)" }}>
        
          <Divider>Top Ten Merchant</Divider>
          <Table
            columns={columnTopTenMerchant}
            dataSource={rowTopTenMerchant[0]}
            scroll={{ x: 1000 }}
            sticky
            size="small"
            pagination={false}
            // style={{width:'600px'}}
          />
        
      </Col>
      </Row>
  )

  return (
    <Content>
      {Pageheader}
      <br></br>
      {totalshop}
      <br></br>
      {Recentshop}<br></br>
      {toptenmer}<br></br>
      {Main_chart}
      <br></br>
      {AllTable}
      <br></br>
     
      
    </Content>
  );
};

export default connect(null, {
  RecentMerchants,
  RecentShops,
  MerchatStatistic,
  ShopStatistics,
  TopTenMerchants
})(MerchantStatistic);
