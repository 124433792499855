import React, { useEffect } from "react";
import {
  Card,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Menu,
  Modal,
  Popconfirm,
  Row,
} from "antd";
import {
  Descriptions,
  Breadcrumb,
  PageHeader,
  Tag,
  Empty,
  Timeline,
  Button,
} from "antd";
import format from 'date-fns/format'

import { useState } from "react";
import { connect } from "react-redux";

import { Content } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";

import {
  CheckCircleOutlined,
  CheckSquareFilled,
  EditOutlined,
  EyeOutlined,
  KeyOutlined,
  StopOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import {
  setMerchantDetails,
  changeMerchantAuth,
  changeMerchantPass,
  editMerchantData,
  updateMerchant,
} from "../../../actions/merchant";
import {
  getOngoingPricingModelDetails,
  getPricingModelForEdit,
  editPrice,
} from "../../../actions/price";
import {
  renderInput,
  renderPasswordField,
  renderSelect,
  renderTextField,
  renderTime,
} from "../../../components/form";
import { Title, Wrapper } from "../../../Style";
import moment from "moment";
import history from "../../../history";

const tailLayout = {
  wrapperCol: { offset: 6, span: 14 },
};
const comapny = [
  { value: "Individual", label: "Individual" },
  { value: "Company", label: "Company" },
];
const is_odd = [
  { value: "true", label: "Active" },
  { value: "false", label: "Inactive" },
];

const PricingDetails = ({
  location,
  getPricingModelForEdit,
  editPrice,
  getOngoingPricingModelDetails,
}) => {
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [placement, setPlacement] = useState("left");
  const [visibleC, setVisibleC] = useState(false);
  const [visibleedit, setVisibleEdit] = useState(false);
  const [remark, setRemark] = useState(false);
  const [id, setId] = useState("");
  const [visibleP, setVisibleP] = useState(false);
  const [auth, setAuth] = useState(false);
  const [active, setActive] = useState(false);
  const [form] = Form.useForm();

  const [visible, setVisible] = useState(false);
  const pid = new URLSearchParams(location.search).get("pricing_id");
  async function fetchData() {
    const pid = new URLSearchParams(location.search).get("pricing_id");
    const res = await getOngoingPricingModelDetails(pid);

    setData(res);
    
  }

  const onFinishEdit = async (values) => {
    const pid = new URLSearchParams(location.search).get("pricing_id");
    console.log(values)
    const code = await editPrice(values, pid);
    form.setFieldsValue({
      password: code?.password,
      remark: code?.remark,
    });
    if (code === 201) {
      setVisibleC(false);
    }
  };
  const onFinishFailedEdit = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };
  async function fetchData1() {
    const pid = new URLSearchParams(location.search).get("pricing_id");
    const res = await getPricingModelForEdit(pid);
    const prevData = { ...res };

   
    form.setFieldsValue({
      name: res.name,
      vat: res.vat,
      pricing_id: res?.pid,
      inCity_delivery_charge: res.in_city.delivery_charge,
      inCity_return_charge: res.in_city.return_charge,
      inCity_cod: res.in_city.cod,
      inCity_base_weight: res.in_city.base_weight,
      inCity_max_weight: res.in_city.max_weight,
      inCity_ECPK: parseInt(res.in_city.ECPK, 10),
      inCity_odd_delivery_charge: res.in_city.ODD_delivery_charge,
      inCity_odd_return_charge: res.in_city.ODD_return_charge,
      inCity_odd_cod: res.in_city.ODD_cod,
      inCity_odd_base_weight: res.in_city.ODD_base_weight,
      inCity_odd_max_weight: res.in_city.ODD_max_weight,
      inCity_odd_ECPK: parseInt(res.in_city.ODD_ECPK, 10),
      subCity_delivery_charge: res.in_sub_city.delivery_charge,
      subCity_return_charge: res.in_sub_city.return_charge,
      subCity_cod: res.in_sub_city.cod,
      subCity_base_weight: res.in_sub_city.base_weight,
      subCity_max_weight: res.in_sub_city.max_weight,
      subCity_ECPK: parseInt(res.in_city.ECPK, 10),
      outerCity_delivery_charge: res.outer_city.delivery_charge,
      outerCity_return_charge: res.in_sub_city.return_charge,
      outerCity_cod: res.in_sub_city.cod,
      outerCity_base_weight: res.in_sub_city.base_weight,
      outerCity_max_weight: res.in_sub_city.max_weight,
      outerCity_ECPK: parseInt(res.in_city.ECPK, 10),
      odd: res.odd.toString(),

        // start_time: res?.odd_time?.start.format(" HH:mm"),
        // end_time: res?.odd_time?.end.format(" HH:mm"),
      // start_time :format((res?.odd_time?.start),'HH:mm'),
      // end_time :format((res?.odd_time?.end),'HH:mm')
        //  end_time: moment(res.odd_time.end).format(" HH:mm"),
        //  start_time: moment(res.odd_time.start).format(" HH:mm"),
    });
    // prevData.odd = res.odd;
    //  prevData.start_time = format((res?.odd_time?.start),'HH:mm')
    //  prevData.end_time = format((res?.odd_time?.end),'HH:mm')


   
    setData1(res);
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
    fetchData1();
  }, []);
  const handleOkEdit = () => {
    setVisibleEdit(false);
  };
  const handleCancel = () => {
    setVisibleC(false);
  };
  const handleCancelEdit = () => {
    setVisibleEdit(false);
  };
  const pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Pricing Details"
      subTitle="Area & Price Management"
      extra={[
        <>
          <Button
            size="small"
            type="primary"
            icon={<EditOutlined />}
            onClick={() => {
              setVisibleEdit(true);
            }}
          >
            Edit
          </Button>
        </>,
      ]}
    ></PageHeader>
  );
  const gridStyle = {
    width: "25%",
    textAlign: "center",
  };
  const DETAILS = (
    <>
      <Row gutter={16}>
        <Col xs={6} lg={6} md={12} sm={24}>
          <Card
            title="Model Name"
            bordered={true}
            style={{
              color: "white",
              backgroundColor: "#f16873",
              textAlign: "center",
            }}
          >
            {data?.name}
          </Card>
        </Col>
        <Col xs={6} lg={6} md={12} sm={24}>
          <Card
            title="Create At "
            bordered={true}
            style={{
              color: "white",
              backgroundColor: "#f16873",
              textAlign: "center",
            }}
          >
            {moment.utc(data?.created_at).format("YYYY-MM-DD hh:mm A")}
          </Card>
        </Col>
        <Col xs={6} lg={6} md={12} sm={24}>
          <Card
            title="Model Status"
            bordered={true}
            style={{
              color: "white",
              backgroundColor: "#f16873",
              textAlign: "center",
            }}
          >
            {data?.active ? <p>Active </p> : <p>Inactive </p>}
          </Card>
        </Col>
        <Col xs={6} lg={6} md={12} sm={24}>
          <Card
            title="Model Vat"
            bordered={true}
            style={{
              color: "white",
              backgroundColor: "#f16873",
              textAlign: "center",
            }}
          >
            {data?.vat}
          </Card>
        </Col>
      </Row>
      <br></br>
      <Card
        style={{
          color: "white",
          backgroundColor: "#f16873",
          textAlign: "center",
        }}
        title="In CITY"
      >
        <Card.Grid style={gridStyle}>ECPK:{data?.in_city?.ECPK}</Card.Grid>
        <Card.Grid style={gridStyle}>ODD:{data?.in_city?.ECPK}</Card.Grid>
        <Card.Grid style={gridStyle}>
          ODD ECPK:{data?.in_city?.ODD_ECPK}
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          ODD base Weight:{data?.in_city?.ODD_base_weight}
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          ODD cod:{data?.in_city?.ODD_cod}
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          ODD Delivery Charge:{data?.in_city?.ODD_delivery_charge}
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          ODD Max Weight:{data?.in_city?.ODD_max_weight}
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          ODD Return Charge:{data?.in_city?.ODD_return_charge}
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          Base Weight:{data?.in_city?.base_weight}
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          Delivery Charge:{data?.in_city?.delivery_charge}
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          Max Weight:{data?.in_city?.max_weight}
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          Return Charge:{data?.in_city?.return_charge}
        </Card.Grid>
      </Card>
      <br></br>
      <Card
        style={{
          color: "white",
          backgroundColor: "#f16873",
          textAlign: "center",
        }}
        title="In Sub CITY"
      >
        <Card.Grid style={gridStyle}>ECPK:{data?.in_sub_city?.ECPK}</Card.Grid>
        <Card.Grid style={gridStyle}>ODD:{data?.in_sub_city?.ECPK}</Card.Grid>

        <Card.Grid style={gridStyle}>
          Base Weight:{data?.in_sub_city?.base_weight}
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          Delivery Charge:{data?.in_sub_city?.delivery_charge}
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          Max Weight:{data?.in_sub_city?.max_weight}
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          Return Charge:{data?.in_sub_city?.return_charge}
        </Card.Grid>
      </Card>
      <br></br>
      <Card
        style={{
          color: "white",
          backgroundColor: "#f16873",
          textAlign: "center",
        }}
        title="Outer CITY"
      >
        <Card.Grid style={gridStyle}>ECPK:{data?.outer_city?.ECPK}</Card.Grid>
        <Card.Grid style={gridStyle}>ODD:{data?.outer_city?.ECPK}</Card.Grid>

        <Card.Grid style={gridStyle}>
          Base Weight:{data?.outer_city?.base_weight}
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          Delivery Charge:{data?.outer_city?.delivery_charge}
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          Max Weight:{data?.outer_city?.max_weight}
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          Return Charge:{data?.outer_city?.return_charge}
        </Card.Grid>
      </Card>
      <Card
        style={{
          color: "white",
          backgroundColor: "#f16873",
          textAlign: "center",
        }}
        title="ODD Time"
      >
        <Card.Grid style={gridStyle}>ODD :{data?.odd ? <strong>Active</strong>:<strong>Inactive</strong>}</Card.Grid>
        <Card.Grid style={gridStyle}>Start Time:{data?.odd_time?.start}</Card.Grid>
        <Card.Grid style={gridStyle}>End Time:{data?.odd_time?.end}</Card.Grid>
      </Card>
      ,
    </>
  );
  const PRICINGEDIT = (
    <>
      <Modal
        title="Pricing Model Edit"
        centered
        visible={visibleedit}
        cancelText="Close"
      okButtonProps={{ style: { display: 'none' } }}
        onCancel={handleCancelEdit}
        width={800}
      >
        {data1 ? (
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
            initialValues={{ remember: true }}
            onFinish={onFinishEdit}
            onFinishFailed={onFinishFailedEdit}
            style={{ alignSelf: "center" }}
            requiredMark={false}
            form={form}
          >
            <Form.Item
              label="Pricing ID"
              hidden={true}
              name="pricing_id"
            ></Form.Item>
            {renderInput("name", "Model Name", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("vat", "Value Added Tax (VAT)", [
              { required: true, message: "This field is required!" },
            ])}{" "}
            <Divider orientation="center">In City</Divider>
            {renderInput("inCity_delivery_charge", "Delivery Charge", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("inCity_return_charge", "Return Charge", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("inCity_cod", "Cash On Delivery (COD)", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("inCity_odd_delivery_charge", "ODD Delivery Charge", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("inCity_odd_return_charge", "ODD Return Charge", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("inCity_odd_cod", "ODD Cash On Delivery (COD)", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("inCity_base_weight", "Base Weight(KG)", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("inCity_max_weight", "Max Weight(KG)", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("inCity_ECPK", "ECPK", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("inCity_odd_base_weight", "ODD Base Weight(KG)", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("inCity_odd_max_weight", "ODD Max Weight(KG)", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("inCity_odd_ECPK", "ODD ECPK", [
              { required: true, message: "This field is required!" },
            ])}
            <Divider orientation="center">In Sub City</Divider>
            {renderInput("subCity_delivery_charge", "Delivery Charge", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("subCity_return_charge", "Return Charge", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("subCity_cod", "Cash On Delivery (COD)", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("subCity_base_weight", "Base Weight(KG)", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("subCity_max_weight", "Max Weight(KG)", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("subCity_ECPK", "ECPK", [
              { required: true, message: "This field is required!" },
            ])}
            <Divider orientation="center">Outer City</Divider>
            {renderInput("outerCity_delivery_charge", "Delivery Charge", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("outerCity_return_charge", "Return Charge", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("outerCity_cod", "Cash On Delivery (COD)", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("outerCity_base_weight", "Base Weight(KG)", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("outerCity_max_weight", "Max Weight(KG)", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("outerCity_ECPK", "EXtra Charge Per KG", [
              { required: true, message: "This field is required!" },
            ])}
            <Divider orientation="center">ODD Time</Divider>
            {/* {roles
              ? renderSelect(
                  "role",
                  "Role",
                  [{ required: true }],
                  "Select a option",
                  option,
                  "none"
                )
              : null} */}
            {renderSelect(
              "odd",
              "Status",
              [{ required: true }],
              "Select a option",
              is_odd,
              "none"
            )}
            {renderTime("start_time", "Start Time", [
              { required: true, message: "Please input your Time" },
            ])}
            {renderTime("end_time", "End Time", [
              { required: true, message: "Please input your Time" },
            ])}
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        ) : null}
      </Modal>
    </>
  );

  return (
    <Content>
      {pageheader}
      <br></br>
      {DETAILS}
      <br></br>
      {PRICINGEDIT}
    </Content>
  );
};

export default connect(null, {
  getOngoingPricingModelDetails,
  getPricingModelForEdit,
  editPrice,
})(PricingDetails);
