import {
  Button,
  Descriptions,
  Empty,
  Form,
  Modal,
  PageHeader,
  Popconfirm,
  Select,
  Tag,
  Timeline,
} from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { Content } from "antd/lib/layout/layout";

import {
  CheckCircleOutlined,
  EditOutlined,
  EyeOutlined,
  KeyOutlined,
  StopOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { Title, Wrapper } from "../../../Style";
import {
  changeMerchantAuth,
  changeMerchantPass,
  editMerchantData,
  setMerchantDetails,
  updateMerchant,
} from "../../../actions/merchant";
import { shopAuthUpdate } from "../../../actions/shop";
import {
  renderInput,
  renderPasswordField,
  renderTextField,
} from "../../../components/form";
import history from "../../../history";
const { Option } = Select;
const tailLayout = {
  wrapperCol: { offset: 6, span: 14 },
};
const comapny = [
  { value: "Individual", label: "Individual" },
  { value: "Company", label: "Company" },
];

const MerchantDetails = ({
  location,
  setMerchantDetails,
  changeMerchantPass,
  changeMerchantAuth,
  shopAuthUpdate,
  editMerchantData,
  updateMerchant,
}) => {
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [typeC, setTypeC] = useState("");
  const [placement, setPlacement] = useState("left");
  const [visibleC, setVisibleC] = useState(false);
  const [visibleedit, setVisibleEdit] = useState(false);
  const [remark, setRemark] = useState(false);
  const [id, setId] = useState("");
  const [visibleP, setVisibleP] = useState(false);
  const [auth, setAuth] = useState(false);
  const [active, setActive] = useState(false);
  const [form] = Form.useForm();
  const [forms] = Form.useForm();

  const [visible, setVisible] = useState(false);
  const [images, setImage] = useState([]);
  const mobile = new URLSearchParams(location.search).get("merchant");
  async function fetchData() {
    const res = await setMerchantDetails(mobile);

    setData(res);

    // console.log('1st',res);
  }
  async function fetchData1() {
    const res = await editMerchantData(mobile);

    setData1(res);

    setTypeC(res?.merchant_type);
    form.setFieldsValue({
      name: res?.name,
      email: res?.email,
      company_name: res?.company_name,

      address: res?.address,
      mobile_number: res?.mobile_number,

      merchant_type: res?.merchant_type,
    });
    // console.log('1st',res);
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
    fetchData1();
  }, []);

  const onFinish = async (values) => {
    const merchant = new URLSearchParams(location.search).get("merchant");
    const code = await changeMerchantPass(merchant, values);
    form.setFieldsValue({
      password: code?.password,
      remark: code?.remark,
    });
    if (code === 201) {
      setVisibleC(false);
      fetchData();
      forms.resetFields();
    }
  };
  const onFinishEdit = async (values) => {
    const code = await updateMerchant(values);
    form.setFieldsValue({
      password: code?.password,
      remark: code?.remark,
    });
    if (code === 201) {
      setVisibleEdit(false);
      fetchData();
      form.resetFields();
    }
  };
  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };
  const onFinishFailedEdit = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };

  const handleVisible = async (mobile_number, shop_id) => {
    //   console.log(mobile_number)
    //   console.log(shop_id)
    history.push(`/shop-details?merchant=${mobile_number}&shop_id=${shop_id}`);
  };

  const handleRefresh = async () => {
    const res = await setMerchantDetails(mobile);
    setData(res);
  };
  const handleOk = () => {
    setVisibleC(false);
  };
  const handleOkEdit = () => {
    setVisibleEdit(false);
  };
  const handleCancel = () => {
    setVisibleC(false);
  };
  const handleCancelEdit = () => {
    setVisibleEdit(false);
  };

  const handleMercahantAuth = async (mobile_number, authenticated) => {
    const code = await changeMerchantAuth(mobile_number, authenticated);
    // console.log('code',code)
    if (code === 201) {
      const res = await setMerchantDetails(mobile);
      //   console.log('new res',res)
      setData(res);
      handleRefresh();
    }
  };
  const handleShopAuth = async (mobile_number, shop_id, authenticated) => {
    const code = await shopAuthUpdate(mobile_number, shop_id, authenticated);
    // console.log('code',code)
    if (code === 201) {
      const res = await setMerchantDetails(mobile);
      //   console.log('new res',res)
      setData(res);
    }
  };

  const pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Merchant Details"
      subTitle="Merchant Management"
      extra={[
        <>
          <Button
            size="small"
            type="primary"
            icon={<EditOutlined />}
            onClick={() => {
              setVisibleEdit(true);
            }}
          >
            Edit
          </Button>

          <Button
            size="small"
            type="primary"
            icon={<KeyOutlined />}
            onClick={() => {
              setVisibleC(true);
            }}
          >
            Password Setting
          </Button>
        </>,
      ]}
    ></PageHeader>
  );
  const merchantdetails = (
    <Wrapper>
      {data ? (
        <>
          <Title>Merchant Information </Title>
          <br />

          <Descriptions>
            <Descriptions.Item label="Name">
              <strong>{data?.name}</strong>
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              <strong>{data?.email}</strong>
            </Descriptions.Item>
            <Descriptions.Item label="Mobile Number">
              <strong>{data?.mobile_number}</strong>
            </Descriptions.Item>

            <Descriptions.Item label="Company Name">
              <strong>{data?.company_name}</strong>
            </Descriptions.Item>
            <Descriptions.Item label="Total Shop">
              <strong>{data?.shop_count}</strong>
            </Descriptions.Item>
            <Descriptions.Item label="Address">
              <strong>{data?.address}</strong>
            </Descriptions.Item>
            <Descriptions.Item label="Date">
              <strong>
                {moment.utc(data?.created_at).format("YYYY-MM-DD hh:mm A")}
                &nbsp; {""}
              </strong>
            </Descriptions.Item>

            <Descriptions.Item label="">
              <strong>
                {data?.authenticated ? (
                  <>
                    <Popconfirm
                      title="Are you sure？"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() =>
                        handleMercahantAuth(data?.mobile_number, false)
                      }
                    >
                      <Button
                        size="small"
                        type="primary"
                        icon={<CheckCircleOutlined />}
                      >
                        Authenticate
                      </Button>
                    </Popconfirm>
                  </>
                ) : (
                  <>
                    <Popconfirm
                      title="Are you sure？"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() =>
                        handleMercahantAuth(data?.mobile_number, true)
                      }
                    >
                      <Button
                        size="small"
                        type="danger"
                        icon={<StopOutlined />}
                      >
                        Unauthenticate
                      </Button>
                    </Popconfirm>
                  </>
                )}
              </strong>
            </Descriptions.Item>
            {data.credentials?.doc_type !== "NONE" ? (
              <>
                <Descriptions.Item label="Doc No">
                  <strong>{data?.credentials?.doc_no}</strong>
                </Descriptions.Item>
                <Descriptions.Item label="Doc Image Front">
                  <div
                    onClick={() =>
                      setImage([
                        `https://api-delivery.redorch.com/images/${data?.credentials?.doc_image_front}`,
                        "Document Front Image",
                      ])
                    }
                  >
                    <img
                      src={`https://api-delivery.redorch.com/images/${data?.credentials?.doc_image_front}`}
                      style={{
                        width: "150px",
                        boxShadow: "0px 0px 9px 6px rgba(138,138,138,0.59)",
                      }}
                      alt="ID Card front image"
                    />
                  </div>

                  <Modal
                    centered
                    visible={images.length == 2 ? true : false}
                    cancelText="Close"
                    okButtonProps={{ style: { display: "none" } }}
                    onCancel={() => setImage([])}
                    width={800}
                  >
                    <h1>{images[1]}</h1>
                    <img
                      src={images[0]}
                      style={{
                        width: "700px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      alt={images[1]}
                    />
                  </Modal>
                </Descriptions.Item>

                <Descriptions.Item label="Doc Image Back">
                  <div
                    onClick={() =>
                      setImage([
                        `https://api-delivery.redorch.com/images/${data?.credentials?.doc_image_back}`,
                        "Document Back Image",
                      ])
                    }
                  >
                    <img
                      src={`https://api-delivery.redorch.com/images/${data?.credentials?.doc_image_back}`}
                      style={{
                        width: "150px",
                        boxShadow: "0px 0px 9px 6px rgba(138,138,138,0.59)",
                      }}
                      alt="ID Card front image"
                    />
                  </div>
                </Descriptions.Item>
              </>
            ) : null}
          </Descriptions>

          {data?.shops ? (
            <>
              {data?.shops.map((shop, index) => (
                <>
                  <Title key={index}>
                    {index + 1} Shop Information
                    <Button
                      size="small"
                      type="primary"
                      style={{ float: "right" }}
                      icon={<EyeOutlined />}
                      onClick={() => {
                        handleVisible(data?.mobile_number, shop?.shop_id);
                      }}
                    >
                      View
                    </Button>
                  </Title>
                  <br />
                  <Descriptions>
                    <Descriptions.Item label="ID">
                      <strong>{shop?.shop_id}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="Name">
                      <strong>{shop?.name}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="Total Balance">
                      <strong>
                        <Tag color="">{shop?.account_balance}</Tag>
                      </strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="Email">
                      <strong>{shop?.email}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="Mobile Number">
                      <strong>{shop?.mobile_number}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="Pickup Address">
                      <strong>{shop?.pickup_address}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="Pickup Area Code">
                      <strong>{shop?.pickup_area_code}</strong>
                    </Descriptions.Item>

                    <Descriptions.Item label="Pickup Type">
                      <strong>{shop?.pickup_type}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="Date">
                      <strong>
                        {moment
                          .utc(shop.created_at)
                          .format("YYYY-MM-DD hh:mm A")}
                      </strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="">
                      <strong>
                        {shop?.authenticated === false ? (
                          <>
                            <Popconfirm
                              title="Are you sure？"
                              okText="Yes"
                              cancelText="No"
                              onConfirm={() =>
                                handleShopAuth(
                                  data?.mobile_number,
                                  shop?.shop_id,
                                  false
                                )
                              }
                            >
                              <Button
                                size="small"
                                type="primary"
                                icon={<StopOutlined />}
                              >
                                Make Authenticate
                              </Button>
                            </Popconfirm>
                          </>
                        ) : (
                          <>
                            <Button
                              size="small"
                              type="secondary"
                              icon={<CheckCircleOutlined />}
                            >
                              Authenticated
                            </Button>
                          </>
                        )}
                      </strong>
                    </Descriptions.Item>
                  </Descriptions>
                </>
              ))}
            </>
          ) : null}
          <br />
          {data.logs ? (
            <>
              <Title>Logs </Title>
              <br />
              {data?.logs ? (
                <Timeline mode="left">
                  {data.logs.map((item, index) => (
                    <Timeline.Item
                      label={moment
                        .utc(item?.created_at)
                        .format("YYYY-MM-DD hh:mm A")}
                      keys={index}
                    >
                      {item?.message}
                    </Timeline.Item>
                  ))}
                </Timeline>
              ) : (
                <Empty />
              )}
            </>
          ) : null}
          <br />
        </>
      ) : null}
    </Wrapper>
  );
  const chnagepass = (
    <>
      <Modal
        title="Change Merchant Password"
        centered
        visible={visibleC}
        cancelText="Close"
        okButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancel}
        height={500}
      >
        {data ? (
          <Form
            name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            style={{ alignSelf: "center" }}
            requiredMark={false}
            form={forms}
          >
            {renderPasswordField("password", "New Password", [
              { required: true, message: "This field is required" },
              {
                pattern: /^[A-Za-z\d@$!%*?&]{8,50}$/,
                message:
                  "Minimum 8 character, allowed special characters are @$!%*?&",
              },
            ])}

            {renderTextField("remark", "Remark", [
              {
                required: true,
                message: "This field is required!",
              },
            ])}

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        ) : null}
      </Modal>
    </>
  );

  const merchantedit = (
    <>
      <Modal
        title="Merchant Edit"
        centered
        visible={visibleedit}
        cancelText="Close"
        okButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancelEdit}
        height={500}
      >
        {data1 ? (
          <Form
            name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinishEdit}
            onFinishFailed={onFinishFailedEdit}
            style={{ alignSelf: "center" }}
            requiredMark={false}
            form={form}
          >
            {renderInput("name", "Full Name", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("mobile_number", "Merchant Mobile", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("email", "Email", [
              { required: true, message: "This field is required!" },
            ])}

            <Form.Item
              label="Merchant Type"
              name="merchant_type"
              rules={[{ required: true, message: "Please input your Type!" }]}
            >
              <Select
                showSearch
                style={{ width: "315px" }}
                name="merchant_type"
                rules={require}
                placeholder="Select One"
                onChange={(e) => {
                  setTypeC(e);
                }}
              >
                <Option value="Individual">Individual</Option>
                <Option value="Company">Company</Option>
              </Select>
            </Form.Item>
            {typeC === "Individual" ? null : (
              <>
                {renderInput("company_name", "Company Name", [
                  { required: true, message: "This field is required!" },
                ])}
              </>
            )}

            {renderTextField("address", "Address", [
              {
                required: true,
                message: "This field is required!",
              },
            ])}
            {renderTextField("remark", "Remark", [
              {
                required: true,
                message: "This field is required!",
              },
            ])}

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        ) : null}
      </Modal>
    </>
  );

  return (
    <Content>
      {pageheader}
      {merchantdetails}
      {chnagepass}
      {merchantedit}
    </Content>
  );
};

export default connect(null, {
  setMerchantDetails,
  changeMerchantPass,
  changeMerchantAuth,
  shopAuthUpdate,
  editMerchantData,
  updateMerchant,
})(MerchantDetails);
