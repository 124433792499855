import {
  Alert,
  Button,
  Comment,
  Descriptions,
  Empty,
  Form,
  PageHeader,
  Tag,
  Tooltip,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  closeTicket,
  getSupportDetails,
  supportFileDownloader,
  supportReply,
} from "../../../actions/support";

import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import { BoxWrapper, MessageBox, Title, Wrapper } from "./detailStyle";

import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import Remarks from "../../../root/remark";
const TicketDetails = ({
  location,
  getSupportDetails,
  supportReply,
  supportFileDownloader,
  closeTicket,
}) => {
  const [data, setData] = useState([]);
  const [review, setReview] = useState(false);
  const [form] = Form.useForm();
  useEffect(async () => {
    const ticket = new URLSearchParams(location.search).get("ticket");
    window.scrollTo(0, 0);
    const res = await getSupportDetails(ticket);
    setData(res);
  }, []);

  const handleRefresh = async () => {
    const res = await getSupportDetails(data?.ticket_id);
    setData(res);
  };

  const makeLocalTime = (date) => {
    let hours = date.split("T")[1].split(":")[0];
    let minutes = date.split("T")[1].split(":")[1];
    var am = true;
    if (hours > 12) {
      am = false;
      hours -= 12;
    } else if (hours == 12) {
      am = false;
    } else if (hours == 0) {
      hours = 12;
    }
    return (
      date.split("T")[0] +
      "," +
      hours +
      ":" +
      minutes +
      " " +
      (am ? "A.M" : "P.M")
    );
  };
  const onFinish = async (values) => {
    const res = await supportReply(values, data?.ticket_id);
    if (res === 201) {
      form.resetFields();
      const res = await getSupportDetails(data?.ticket_id);
      setData(res);
    }

    // console.log('Received values of form:', values);
  };
  const props = {
    name: "file",
    action:
      "https://api-delivery.redorch.com/archon/v1/message-management/file/upload",
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },

    onRemove: (file) => {
      const res = axios(
        "https://api-delivery.redorch.com/archon/v1/message-management/file/delete",
        {
          headers: {
            "content-type": "application/json",
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          data: {
            file: file?.response?.name,
          },
          method: "delete",
        }
      );
    },
  };
  return (
    <Content>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Ticket Details"
        subTitle="Support Management"
      ></PageHeader>
      <Wrapper>
        <h2>Title : {data?.title}</h2>
        <h3>
          {!data?.admin_seen ? (
            <Tag color="#f50">Not-Replied</Tag>
          ) : (
            <Tag color="#108ee9">Replied</Tag>
          )}
        </h3>
        {/* <h3>Total Bill: ${data?.total_cost}</h3> */}
        <Tag color="#2db7f5">Ticket ID: {data?.ticket_id}</Tag>
        {data?.status === "Closed" ? (
          <Tag color="#00b894">Status: {data?.status}</Tag>
        ) : (
          <Tag color="#ff7675">Status: {data?.status}</Tag>
        )}

        <Tag color="#cd2df5">
          {data?.created_at ? makeLocalTime(data?.created_at) : null}
        </Tag>
        <br />
        <br />
        {data ? (
          <>
            <Title style={{ backgroundColor: "#3ab569" }}>
              Merchant Information
            </Title>
            <Descriptions>
              <Descriptions.Item label="NAME">
                <strong>{data?.merchant?.name?.toUpperCase()}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="MOBILE">
                <strong>{data?.merchant?.mobile_number}</strong>
              </Descriptions.Item>
            </Descriptions>

            <Title style={{ backgroundColor: "#3ab569" }}>
              Messages History
            </Title>
            {data?.message_logs ? (
              <BoxWrapper>
                {data?.message_logs.map((item, id) => (
                  <MessageBox user={item?.is_user}>
                    <Comment
                      author={
                        item?.is_user ? (
                          <span style={{ color: "#000", fontWeight: "bold" }}>
                            {data?.client?.name} (Client)
                          </span>
                        ) : (
                          <span style={{ color: "#000", fontWeight: "bold" }}>
                            {item?.admin?.name} (Admin)
                          </span>
                        )
                      }
                      content={
                        <p>
                          {item?.message}
                          <br />
                          {item?.document ? (
                            <Button
                              type="primary"
                              icon={<DownloadOutlined />}
                              size="small"
                              onClick={() =>
                                supportFileDownloader(item?.document)
                              }
                            >
                              Download
                            </Button>
                          ) : null}
                        </p>
                      }
                      datetime={
                        <Tooltip
                          title={makeLocalTime(item.created_at).split(",")[0]}
                        >
                          <span style={{ color: "#000" }}>
                            {makeLocalTime(item.created_at).split(",")[1]}
                          </span>
                        </Tooltip>
                      }
                    />
                  </MessageBox>
                ))}
                {data?.status === "Active" ? (
                  <Form
                    form={form}
                    name="dynamic_form_nest_item"
                    onFinish={onFinish}
                    autoComplete="off"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Form.Item
                      name="text"
                      style={{
                        alignSelf: "flex-end",
                        width: "70%",
                        marginBottom: "0px",
                      }}
                      rules={[{ required: true, message: "Required" }]}
                    >
                      <TextArea rows={4} name="text" allowClear />
                    </Form.Item>
                    <br />

                    <Form.Item name="file" style={{ alignSelf: "flex-end" }}>
                      <Upload {...props} accept="png">
                        <Button icon={<UploadOutlined />}>Upload</Button>
                      </Upload>
                    </Form.Item>
                    <Button
                      htmlType="submit"
                      type="primary"
                      size="middle"
                      style={{
                        width: "150px",
                        display: "inline-block",
                        alignSelf: "flex-end",
                      }}
                    >
                      Reply
                    </Button>
                    <br />
                    <br />

                    <Button
                      type="text"
                      size="middle"
                      style={{ backgroundColor: "#fc5c65", color: "#fff" }}
                      onClick={() => setReview(true)}
                    >
                      Close this ticket
                    </Button>
                  </Form>
                ) : (
                  <Alert message="This ticket is closed.......!" type="info" />
                )}
              </BoxWrapper>
            ) : (
              <Empty />
            )}
          </>
        ) : null}
      </Wrapper>
      <Remarks
        title=" "
        btn="Submit"
        placement="bottom"
        visible={review}
        fnc={() => setReview(false)}
        id={data?.ticket_id}
        delFnc={closeTicket}
        refresh={handleRefresh}
      />
      {/* <Remarks title=" " btn="Refund" placement="bottom" visible={refund} fnc={()=>setRefund(false)} id={data?.order_id} delFnc = {refundFinanceOrder} refresh = {handleRefresh} />
      <Remarks title=" " btn="Reject" placement="bottom" visible={reject} fnc={onRejectClose} id={data?.order_id} delFnc = {rejectFinanceOrder} refresh = {handleRefresh} /> */}
    </Content>
  );
};

export default connect(null, {
  getSupportDetails,
  supportReply,
  supportFileDownloader,
  closeTicket,
})(TicketDetails);
