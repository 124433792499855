import axios from "axios";
import history from "../history";
import {
  AUTH_ERROR,
  ERROR_MESSAGE,
  LOADED,
  LOADING,
  SUCCESS_MESSAGE,
  VALIDATION_AUTH_ERROR,
  VALIDATION_ERROR,
} from "../reducers/type";
import api from "./apis/index";

export const ParcelStatistics = () => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const response = await api(localStorage.getItem("token")).get(
      "/order-management/order-statistics"
    );
    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if (err.message == "Network Error") {
      history.push("/networkError");
      return;
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};
export const makeReturn =
  (shop, merchant, recipient_mobile_number, order_id, { remark }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });
    const obj = {
      shop: shop,
      merchant: merchant,
      recipient_mobile_number: recipient_mobile_number,
      order_id: order_id,
      remark: remark,
    };

    // dispatch({ type: LOADING });
    try {
      const response = await api(localStorage.getItem("token")).patch(
        "order-management/return-order",
        { ...obj }
      );

      if (response.status === 201) {
        const res = await api(localStorage.getItem("token")).get(
          `order-management/get-order-details?merchant=${merchant}&order=${order_id}&shop=${shop}`
        );

        if (res.status === 200) {
          dispatch({ type: LOADED });
          localStorage.setItem("parcel", JSON.stringify(res.data.order));
          dispatch({
            type: SUCCESS_MESSAGE,
            payload: "Order is returned succesfully",
          });
          return res.data.order;

          // history.push('/parcelDetails')
        }

        dispatch({ type: LOADED });
        // history.push(`/storeDetails?shop_id=${data.shop_id}`);
      } else {
        dispatch({ type: LOADED });
      }
    } catch (err) {
      /* =============Start========== */
      if (err?.response?.status === 422) {
        err.response.data.errors.map((item) => {
          dispatch({
            type: VALIDATION_AUTH_ERROR,
            payload: [{ message: item?.message, path: item?.path[0] }],
          });
        });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
        localStorage.clear();
        history.push("/login");
      }
      if (err.message == "Network Error") {
        history.push("/networkError");
        return;
      }
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response
          ? err.response.data.message
          : history.push("/notFound"),
      });
      dispatch({ type: LOADED });
      /* ===========End============*/
    }
  };
export const deleteParcel =
  (shop, merchant, recipient_mobile_number, order_id, { remark }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });
    let from = new Date();
    let to = new Date();
    const dd = String(from.getDate()).padStart(2, "0");
    const ldd = String(to.getDate()).padStart(2, "0");
    const mm = String(from.getMonth() + 1).padStart(2, "0"); //January is 0!
    const lmm = String(to.getMonth()).padStart(2, "0"); //January is 0!
    const yyyy = from.getFullYear();
    const lyyyy = to.getFullYear();

    to = yyyy + "-" + mm + "-" + dd;
    from = lyyyy + "-" + lmm + "-" + ldd;
    const obj = {
      shop: shop,
      merchant: merchant,
      recipient_mobile_number: recipient_mobile_number,
      order_id: order_id,
      remark: remark,
    };

    // dispatch({ type: LOADING });
    try {
      const response = await api(localStorage.getItem("token")).patch(
        "order-management/cancel-order",
        { ...obj }
      );
      if (response.status === 201) {
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: "Order is Canceled succesfully",
        });
        dispatch({ type: LOADED });
        return response.status;
      }
    } catch (err) {
      /* =============Start========== */
      if (err?.response?.status === 422) {
        err.response.data.errors.map((item) => {
          dispatch({
            type: VALIDATION_AUTH_ERROR,
            payload: [{ message: item?.message, path: item?.path[0] }],
          });
        });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
        localStorage.clear();
        history.push("/login");
      }
      if (err.message == "Network Error") {
        history.push("/networkError");
        return;
      }
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response
          ? err.response.data.message
          : history.push("/notFound"),
      });
      dispatch({ type: LOADED });
      /* ===========End============*/
    }
  };

export const ParcelBySearch =
  (p, moi, oi, m, s, rmn, t, dt, ti, st, ds, de) => async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const response = await api(localStorage.getItem("token")).get(
        `order-management/order?p=${p ? p : 1}&merchant_order_id=${
          moi ? moi : ""
        }&order_id=${oi ? oi : ""}&merchant=${m ? m : ""}&shop=${
          s ? s : ""
        }&recipient_mobile_number=${rmn ? rmn : ""}&type=${
          t ? t : ""
        }&delivery_time=${dt ? dt : ""}&time=${ti ? ti : ""}&state=${
          st ? st : ""
        }&startDate=${ds ? ds : ""}&endDate=${de ? de : ""}`
      );
      if (response.status === 200) {
        dispatch({ type: LOADED });
        localStorage.setItem(
          "search_parcel",
          JSON.stringify(response.data.orders)
        );
        return response.data;
      }
    } catch (err) {
      if (err?.response?.status === 422) {
        err.response.data.errors.map((item) => {
          dispatch({
            type: VALIDATION_AUTH_ERROR,
            payload: [{ message: item?.message, path: item?.path[0] }],
          });
        });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
        localStorage.clear();
        history.push("/login");
      }
      if (err.message == "Network Error") {
        history.push("/networkError");
        return;
      }
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response
          ? err.response.data.message
          : history.push("/notFound"),
      });
      dispatch({ type: LOADED });
    }
  };

export const CreateOrderParcel =
  (data, custom, merchants, city, subCity, thana, area) => async (dispatch) => {
    dispatch({ type: LOADING });

    let dt = data?.delivery_type ? data?.delivery_type : "ND";
    const obj = {
      merchant: merchants,
      shop_id: data?.shop_id,
      merchant_order_id: data?.merchant_order_id
        ? data?.merchant_order_id
        : "N/A",
      recipient_name: data?.recipient_name,
      recipient_mobile_number: data?.recipient_phone,
      recipient_address: data?.recipient_address,
      recipient_area_code: city + "-" + subCity + "-" + thana + "-" + area,
      weight: data?.weight * 1000,
      quantity: data?.quantity,
      item_description: data?.item_description,
      note: data?.note,
      amount_to_collect: data?.amount_to_collect,
      item_actual_price: data?.item_actual_price,
      product_type: data?.product_type,
      is_tp: custom,
      tp_name: data?.tp_name,
      tp_charge: data?.tp_charge,
      // pickup_address:data?.pickup_address ? data?.pickup_address : "",
      delivery_time: dt,
    };

    try {
      const response = await api(localStorage.getItem("token")).post(
        "order-management/create-order",
        { ...obj }
      );

      if (response.status === 201) {
        dispatch({ type: LOADED });
        dispatch({ type: SUCCESS_MESSAGE, payload: response.data?.message });
        return response.status;
        // history.push("/search-parcel");
      }
    } catch (err) {
      if (err?.response?.status === 422) {
        err.response.data.errors.map((item) => {
          dispatch({
            type: VALIDATION_AUTH_ERROR,
            payload: [{ message: item?.message, path: item?.path[0] }],
          });
        });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
        localStorage.clear();
        history.push("/login");
      }
      if (err.message == "Network Error") {
        history.push("/networkError");
        return;
      }
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response
          ? err.response.data.message
          : history.push("/notFound"),
      });
      dispatch({ type: LOADED });
    }
  };

export const getValidMerchantShop = (merchant) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `order-management/valid-merchant-shops?merchant=${merchant}`
    );
    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data.shops;
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if (err.message == "Network Error") {
      history.push("/networkError");
      return;
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const createCustomOrder =
  (data, custom, merchants, city, subCity, thana, area) => async (dispatch) => {
    dispatch({ type: LOADING });
    let dt = data.delivery_type ? data.delivery_type : "ND";
    const obj = {
      merchant: merchants,
      shop_id: data.shop_id,
      merchant_order_id: data.merchant_order_id
        ? data.merchant_order_id
        : "N/A",
      recipient_name: data.recipient_name,
      recipient_mobile_number: data.recipient_phone,
      recipient_address: data.recipient_address,
      recipient_area_code: city + "-" + subCity + "-" + thana + "-" + area,
      weight: data.weight * 1000,
      quantity: data.quantity,
      item_description: data.item_description,
      note: data.note,
      amount_to_collect: data.amount_to_collect,
      item_actual_price: data.item_actual_price,
      product_type: data.product_type,
      delivery_time: dt,
      delivery_charge: data.delivery_charge,
      return_charge: data.return_charge,
      cod: data.cod,
      is_tp: custom,
      tp_name: data?.tp_name,
      tp_charge: data?.tp_charge,
    };

    try {
      const response = await api(localStorage.getItem("token")).post(
        "order-management/custom-create-order",
        { ...obj }
      );

      if (response.status === 201) {
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: "Custom Order is created succesfully",
        });
        dispatch({ type: LOADED });
        return response.status;
        // history.push("/search-parcel");
      } else {
        dispatch({ type: LOADED });
      }
    } catch (err) {
      /* =============Start========== */
      if (err?.response?.status === 422) {
        err.response.data.errors.map((item) => {
          dispatch({
            type: VALIDATION_AUTH_ERROR,
            payload: [{ message: item?.message, path: item?.path[0] }],
          });
        });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
        localStorage.clear();
        history.push("/login");
      }
      if (err.message == "Network Error") {
        history.push("/networkError");
        return;
      }
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response
          ? err.response.data.message
          : history.push("/notFound"),
      });
      dispatch({ type: LOADED });
      /* ===========End============*/
    }
  };

export const getParcelDetails = (merchant, order, shop) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `order-management/get-order-details?merchant=${merchant}&order=${order}&shop=${shop}`
    );
    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data.order;
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if (err.message == "Network Error") {
      history.push("/networkError");
      return;
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const editParcelDetails =
  (recipient_mobile_number, order_id, shop, merchant) => async (dispatch) => {
    dispatch({ type: LOADING });
    try {
      const response = await api(localStorage.getItem("token")).get(
        `order-management/get-edit-order?recipient_mobile_number=${recipient_mobile_number}&order_id=${order_id}&shop=${shop}&merchant=${merchant}`
      );
      if (response.status === 200) {
        dispatch({ type: LOADED });
        return response.data;
      }
    } catch (err) {
      /* =============Start========== */
      if (err?.response?.status === 422) {
        err.response.data.errors.map((item) => {
          dispatch({
            type: VALIDATION_AUTH_ERROR,
            payload: [{ message: item?.message, path: item?.path[0] }],
          });
        });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
        localStorage.clear();
        history.push("/login");
      }
      if (err.message == "Network Error") {
        history.push("/networkError");
        return;
      }
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response
          ? err.response.data.message
          : history.push("/notFound"),
      });
      dispatch({ type: LOADED });
      /* ===========End============*/
    }
  };

export const updateOrder =
  (data, merchantmobile, custom) => async (dispatch) => {
    dispatch({ type: LOADING });
    // console.log(data)
    let dt = data.delivery_type ? data.delivery_type : "ND";
    console.log(data);
    const obj = {
      merchant: merchantmobile,
      shop_id: data.shop_id,
      order_id: data.order_id,
      merchant_order_id: data.merchant_order_id
        ? data.merchant_order_id
        : "N/A",
      recipient_name: data.recipient_name,
      recipient_mobile_number: data.recipient_mobile_number,
      recipient_address: data.recipient_address,
      recipient_area_code:
        data.city + "-" + data.subCity + "-" + data.thana + "-" + data.area,
      weight: data.weight * 1000,
      quantity: data.quantity,
      item_description: data.item_description,
      note: data.note,
      is_tp: custom,
      tp_name: data.tp_name,
      tp_charge: data.tp_charge,

      amount_to_collect: data.amount_to_collect,
      item_actual_price: data.item_actual_price,
      product_type: data.product_type,
      delivery_time: dt,
      remark: data.remark,
    };

    dispatch({ type: LOADING });
    try {
      const response = await api(localStorage.getItem("token")).patch(
        "order-management/update-order",
        { ...obj }
      );

      if (response.status === 201) {
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: "Your Order is updated succesfully",
        });
        dispatch({ type: LOADED });
        history.push(`/search-parcel`);
      } else {
        dispatch({ type: LOADED });
      }
    } catch (err) {
      /* =============Start========== */
      if (err?.response?.status === 422) {
        err.response.data.errors.map((item) => {
          dispatch({
            type: VALIDATION_ERROR,
            payload: [{ message: item.message, path: item.path[0] }],
          });
        });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
        localStorage.clear();
        history.push("/login");
      }
      if (err.message == "Network Error") {
        history.push("/networkError");
        return;
      }
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response
          ? err.response.data.message
          : history.push("/notFound"),
      });
      dispatch({ type: LOADED });
      /* ===========End============*/
    }
  };
export const updateCustomOrder = (data, k, custom) => async (dispatch) => {
  dispatch({ type: LOADING });
  let dt = data.delivery_type ? data.delivery_type : "ND";
  const obj = {
    merchant: k,
    order_id: data.order_id,
    shop_id: data.shop_id,
    merchant_order_id: data.merchant_order_id ? data.merchant_order_id : "N/A",
    recipient_name: data.recipient_name,
    recipient_mobile_number: data.recipient_mobile_number,
    recipient_address: data.recipient_address,
    recipient_area_code:
      data.city + "-" + data.subCity + "-" + data.thana + "-" + data.area,
    weight: data.weight * 1000,
    quantity: data.quantity,
    item_description: data.item_description,
    note: data.note,
    amount_to_collect: data.amount_to_collect,
    item_actual_price: data.item_actual_price,
    product_type: data.product_type,
    delivery_time: dt,
    is_tp: custom,
    tp_name: data.tp_name,
    tp_charge: data.tp_charge,

    delivery_charge: data.delivery_charge,
    return_charge: data.return_charge,
    cod: data.cod,
    remark: data.remark,
  };

  try {
    const response = await api(localStorage.getItem("token")).patch(
      "order-management/custom-update-order",
      { ...obj }
    );

    if (response.status === 201) {
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Custom Order is updated succesfully",
      });
      dispatch({ type: LOADED });
      history.push(`/search-parcel`);
    } else {
      dispatch({ type: LOADED });
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if (err.message == "Network Error") {
      history.push("/networkError");
      return;
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};
export const deliverParcel = (order_id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const obj = {
    order_id: order_id,
  };
  try {
    const response = await api(localStorage.getItem("token")).patch(
      `/order-management/deliver-order`,
      { ...obj }
    );
    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Parcel Delivered Successfull..!",
      });
      return response.status;
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if (err.message == "Network Error") {
      history.push("/networkError");
      return;
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const getOutForDeliveryParcel = () => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const response = await api(localStorage.getItem("token")).get(
      `/order-management/out-for-delivery`
    );
    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if (err.message == "Network Error") {
      history.push("/networkError");
      return;
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

// ============Create Buld Order======
export const createBulkOrder =
  (shop_id, mobilenumber, data, name) => async (dispatch) => {
    dispatch({ type: LOADING });

    const bulk = [];
    data?.map((item, id) => {
      if (
        item.merchant_order_id !== "" ||
        item.recipient_name !== "" ||
        item.recipient_mobile_number !== "" ||
        item.recipient_address !== "" ||
        item.city !== "" ||
        item.area !== "" ||
        item.weight !== "" ||
        item.product_type !== "" ||
        item.quantity !== "" ||
        item.amount_to_collect !== "" ||
        item.item_description !== "" ||
        item.tp_name !== "" ||
        item.tp_charge !== ""
      ) {
        if (item.tp_name == undefined) {
          item.tp_name = "bahon";
        }
        if (item.tp_charge == undefined) {
          item.tp_charge = 0;
        }
        //delete item?.city;
        //delete item?.area;

        bulk.push(item);
      }
    });
    const obj = {
      shop_id: shop_id,
      merchant: mobilenumber,
      bulk: bulk,
    };

    dispatch({ type: LOADING });
    try {
      const response = await api(localStorage.getItem("token")).post(
        "order-management/create-bulk-order",
        { ...obj }
      );
      if (response.status === 201) {
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: "Your Order is created succesfully",
        });
        dispatch({ type: LOADED });
        return response.status;
        // history.push(`/orderList?shop_id=${shop_id}&name=${name}`);
      } else {
        dispatch({ type: LOADED });
      }
    } catch (err) {
      /* =============Start========== */
      if (err?.response?.status === 422) {
        err.response.data.errors.map((item) => {
          dispatch({
            type: VALIDATION_AUTH_ERROR,
            payload: [
              {
                message: `${item?.message} "${
                  item?.context?.value
                }"  check row ${item?.path[1] + 1}`,
                path: item?.path[0],
                duration: 5000,
              },
            ],
          });
        });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
        localStorage.clear();
        history.push("/login");
      }
      if (err.message == "Network Error") {
        history.push("/networkError");
        return;
      }
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response
          ? err.response.data.message
          : history.push("/notFound"),
      });
      dispatch({ type: LOADED });
    }
  };
export const getParcelReport =
  (moi, oi, m, s, rmn, t, dt, ti, st, ds, de) => async (dispatch) => {
    dispatch({ type: LOADING });
    // let date = new Date();
    // let ldate = new Date();
    // const dd = String(date.getDate()).padStart(2, '0');
    // const ldd = String(ldate.getDate()).padStart(2, '0');
    // const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    // const lmm = String(ldate.getMonth()).padStart(2, '0'); //January is 0!
    // const yyyy = date.getFullYear();
    // const lyyyy = ldate.getFullYear();

    // date = yyyy + '-' + mm + '-' + dd;
    // ldate = lyyyy + '-' + lmm + '-' + ldd;
    // const startDate = data?.startDate ? data?.startDate : ldate;
    // const endDate = data?.endDate ? data?.endDate : date;
    try {
      // const response = await api(localStorage.getItem("token")).get(`order-management/order/xlsx/?merchant_order_id=${data?.merchant_order_id}&order_id=${data?.order_id ? data?.order_id : ''}&merchant=${data?.merchant}&shop=${data?.shop}&recipient_mobile_number=${data?.recipient_mobile_number}&type=${data?.type ? data?.type : ''}&delivery_time=${data?.delivery_time ? data?.delivery_time : ''}&time=${data?.time ? data?.time : ''}&state=${data?.state ? data?.state : ''}&startDate=${data?.startDate ? data?.startDate : ''}&endDate=${data?.endDate ? data?.endDate : ''}`);
      // if(response.status === 200 ) {
      //   dispatch({ type: LOADED });
      //   console.log(response)
      //   // return response.data;
      // }

      const response = await axios(
        `https://api-delivery.redorch.com/archon/v1/order-management/order/xlsx/?merchant_order_id=${
          moi ? moi : ""
        }&order_id=${oi ? oi : ""}&merchant=${m ? m : ""}&shop=${
          s ? s : ""
        }&recipient_mobile_number=${rmn ? rmn : ""}&type=${
          t ? t : ""
        }&delivery_time=${dt ? dt : ""}&time=${ti ? ti : ""}&state=${
          st ? st : ""
        }&startDate=${ds ? ds : ""}&endDate=${de ? de : ""}`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          method: "GET",
          responseType: "blob",
        }
      );

      if (response.status === 200) {
        // FileDown
        dispatch({ type: LOADED });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `orders.xlsx`);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      }
    } catch (err) {
      /* =============Start========== */
      if (err?.response?.status === 422) {
        err.response.data.errors.map((item) => {
          dispatch({
            type: VALIDATION_ERROR,
            payload: [{ message: item.message, path: item.path[0] }],
          });
        });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
        localStorage.clear();
        history.push("/login");
      }
      if (err.message == "Network Error") {
        history.push("/networkError");
        return;
      }
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response
          ? err.response.data.message
          : history.push("/notFound"),
      });
      dispatch({ type: LOADED });
      /* ===========End============*/
    }
  };

// export const createBulkOrder =
//   (data, shop_id, merchant) => async (dispatch) => {
//     const bulk = [];
//     data.bulkdata?.map((item, id) => {
//       if (
//         item?.merchant_order_id !== "" ||
//         item?.recipient_name !== "" ||
//         item?.recipient_mobile_number !== "" ||
//         item?.recipient_address !== "" ||
//         item?.city !== "" ||
//         item?.subCity !== "" ||
//         item?.thana !== "" ||
//         item?.weight*1000 !== "" ||
//         item?.product_type !== "" ||
//         item?.quantity !== "" ||
//         item?.amount_to_collect !== "" ||
//         item?.item_description !== "" ||
//         item?.recipient_area_code ===
//           item?.city +
//             "-" +
//             item?.subCity +
//             "-" +
//             item?.thana +
//             "-" +
//             item?.thana
//       ) {
//         delete item?.city;
//         delete item?.subCity;
//         delete item?.thana;

//         bulk.push(item);
//       }
//     });
//     const obj = {
//       shop_id: shop_id,
//       merchant: merchant,
//       bulk: bulk,
//     };

//     dispatch({ type: LOADING });
//     try {
//       const response = await api(localStorage.getItem("token")).post(
//         "order-management/create-bulk-order",
//         { ...obj }
//       );

//       if (response.status === 201) {
//         dispatch({
//           type: SUCCESS_MESSAGE,
//           payload: "Your Order is created succesfully",
//         });
//         dispatch({ type: LOADED });
//         return response.status;
//         // history.push(`/orderList?shop_id=${shop_id}&name=${name}`);
//       } else {
//         dispatch({ type: LOADED });
//       }
//     } catch (err) {
//       if (err.response) {
//         dispatch({ type: LOADED });
//         if (err.response.status === 401) {
//           localStorage.clear();
//           history.push("/login");
//         }
//       }
//       dispatch({ type: LOADED });
//       dispatch({
//         type: ERROR_MESSAGE,
//         payload: err.response
//           ? err.response.data.message
//           : history.push("/opps"),
//       });
//     }
//   };

export const BulkExcelDownloader = () => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const response = await api(localStorage.getItem("token")).get(
      `order-management/bulk/order/sample`,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        method: "GET",
        responseType: "blob",
      }
    );

    if (response.status === 200) {
      // FileDown
      dispatch({ type: LOADED });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Bahon_Smaple.xlsx`);
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }
  } catch (err) {
    if (err?.response?.status === 451) {
      history.push("/pending-verification");
    }
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }

    if (err.message == "Network Error") {
      history.push("/notFound");
      return;
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/404"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};
