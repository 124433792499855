import {
    CheckCircleOutlined,
    EditOutlined,
    FontSizeOutlined,
    StopOutlined,SearchOutlined,EyeOutlined
  } from "@ant-design/icons";
  import {
    Button,
    Card,
    Col,
    Descriptions,
    Divider,
    Empty,
    Form,
    Input,
    Modal,
    PageHeader,
    Popconfirm,
    Row,
    Tag,
    Timeline,Table
  } from "antd";
  import { Content } from "antd/lib/layout/layout";
  import moment from "moment";
  import React, { useState } from "react";
  import { useEffect } from "react";
  import { connect } from "react-redux";
  import styled from "styled-components";
  import { getLoanDetails,ForPayLoan } from "../../../actions/account";
  import history from "../../../history";
import TransactionDetails from "../Total_transaction/TransactionDetails";
import PayLoan from "./PayLoan";
import ReceiveLoan from "./ReceiveLoan";
  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };
  const gender = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Other" },
  ];
  const status = [
    { value: true, label: "Active" },
    { value: false, label: "Inactive" },
  ];
  const Wrapper = styled.div`
    padding: 20px;
  `;
  const Title = styled.div`
    width: 100%;
    background-color: #f16873;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 5px;
    text-align: center;
  `;
  const LoanDetails = ({ location, getLoanDetails,ForPayLoan }) => {
    const [Id, setId] = useState("");
    const [data, setData] = useState([]);
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [transactionid, settransactionid] = useState(null);
    const [payloanvisible, setpayloanvisible] = useState(false);
    const [receiveloanvisible, setreciveloanvisible] = useState(false);
    const id = new URLSearchParams(location.search).get("loan_id");
    useEffect(async () => {
      window.scrollTo(0, 0);
      
      const res = await getLoanDetails(id);
     
      setData(res);
    }, []);
    const handleRefresh=async()=>{
      const res = await getLoanDetails(id);
      setData(res);
    }
    
  
    const handleCancel = () => {
      settransactionid(null)
      setIsModalVisible(false);
    };
    const handleVisible = async (id) => {
      setIsModalVisible(true);
      
      };
     
     
    const pageheader = (
      <>
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title="Loan Details"
          subTitle="Finance  Management"
          extra={[
            <>
            {data?.loan_state==='paid'? <strong style={{color:'red',fontSize:'20px'}}>Paid</strong>:
            <>
          
         { data?.state==='taken'?
            <>
             <strong style={{color:'red',fontSize:'20px'}}>{data?.loan_state}</strong>&nbsp;
            <Button
            style={{ backgroundColor: "#3ab569", color: "white" }}
            onClick={() => {
              setreciveloanvisible(true);
              
            }}
          >
            Pay Installment
          </Button>
         
            
          </>
          :
          <>
        
          
          <strong style={{color:'red',fontSize:'20px'}}>{data?.loan_state}</strong>  &nbsp;
          <Button style={{ backgroundColor: "#3ab569", color: "white" }}
          onClick={() => {
            setpayloanvisible(true);
          }}
          >
            Receive Installment
          </Button>
          </>}
          </>

            
          
        }
        <ReceiveLoan
          title="Receive Installment"
          visible={payloanvisible}
          cancel={() => setpayloanvisible(false)}
          ok={() => setpayloanvisible(false)}
          api={ForPayLoan}
          refresh={handleRefresh}
          ID={id}
        />
        <PayLoan
          title="Pay Installment
          "
          visible={receiveloanvisible}
          cancel={() => setreciveloanvisible(false)}
          ok={() => setreciveloanvisible(false)}
          api={ForPayLoan}
          refresh={handleRefresh}
          ID={id}
          />
              
            </>,
          ]}
        ></PageHeader>
        <br></br>
      </>
    );

    const searchColumns = (dataIndex) => ({
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => {
          return (
            <div style={{ padding: 8 }}>
              <Input
                autoFocus
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={(e) => {
                  setSelectedKeys(e.target.value ? [e.target.value] : []);
                  confirm({ closeDropdown: false });
                }}
                onPressEnter={() => {
                  confirm();
                }}
                onBlur={() => {
                  confirm();
                }}
                style={{ marginBottom: 8, display: "block" }}
              ></Input>
              <Button
                onClick={() => {
                  confirm();
                }}
                type="primary"
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              &nbsp;
              <Button
                onClick={() => {
                  clearFilters();
                }}
                size="small"
                type="danger"
                style={{ width: 90 }}
              >
                Reset
              </Button>
            </div>
          );
        },
        filterIcon: () => {
          return <SearchOutlined />;
        },
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())
            : "",
      });

      const columns = [
        {
          title: "Transaction ID",
          dataIndex: "transaction_id",
          key: "transaction_id",
          width: "25%",
          ...searchColumns("transaction_id"),
        },
        {
          title: "Transaction State",
          dataIndex: "transaction_state",
          key: "transaction_state",
          width: "20%",
          ...searchColumns("transaction_state"),
        },
        {
          title: "Bahon AC",
          dataIndex: "bahon",
          key: "bahon",
          width: "20%",
          ...searchColumns("bahon"),
        },
        
        {
          title: "Date",
          dataIndex: "created_at",
          key: "created_at",
          width: "18%",
        },
    
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          width: "15%",
        },
      ];
      const createData = (
        transaction_id,
        transaction_state,
        bahon,
        created_at,
        action
      ) => {
        return {
          transaction_id,
          transaction_state,
          bahon,
          created_at,
    
          action,
        };
      };
    
      const rows = [
        data
          ? data?.transaction_list?.map((TRANSACTION, id) =>
              createData(
                TRANSACTION?.transaction_id,
                TRANSACTION?.transaction_state,
                TRANSACTION?.bahon,
                
                moment.utc(TRANSACTION.created_at).format("YYYY-MM-DD hh:mm A"),
                <Button
                  size="small"
                  type="primary"
                  icon={<EyeOutlined />}
                  target="_blank"

                  // href={`http://localhost:3000/transaction-details?transaction_id=${TRANSACTION?.transaction_id}`}
                  onClick={()=>{
                    setIsModalVisible(true);
                    settransactionid(TRANSACTION?.transaction_id)}}
                >
                  View
                </Button>
              )
            )
          : "",
      ];
    
  
    const detailsuser = (
      <>
        
          {data? (
            <>
            <Divider style={{ color: "#3ab569",fontSize:'24px' }}>{data?.state ==='given'?<strong>Given </strong>:<strong>Taken</strong>} Loan </Divider>
              <Row gutter={16}>
                <Col xs={12} lg={12} sm={24} md={24}>
                  <Card hoverable style={{maxHeight:'250px'}}>
                    <>
                      <Title>{data?.state ==='given'?<strong>Borrower </strong>:<strong>Receiver</strong>} Information </Title>
                      <br />
                      <Descriptions>
                        <Descriptions.Item label="Name" span={1.5}>
                          <strong>{data?.info?.name}</strong>
                        </Descriptions.Item>
                        </Descriptions>
                        <Descriptions>
                      
                        <Descriptions.Item label="Mobile Number" span={1.5}>
                          <strong>
                            {data?.info?.mobile_number}
                          </strong>
                        </Descriptions.Item>
                      </Descriptions>
                      <Descriptions>
                      <Descriptions.Item label="Type" span={2}>
                          <strong>{data?.info?.type}</strong>
                        </Descriptions.Item>
                      </Descriptions>
                      <Descriptions>
                        <Descriptions.Item label="Description" span={2}>
                          <strong>{data?.info?.description}</strong>
                        </Descriptions.Item>
                        </Descriptions>
                        
                        
                    </>
                  </Card>
                  <br></br>
                </Col>
                <Col xs={12} lg={12} sm={24} md={24}>
                  <Card hoverable style={{maxHeight:'250px'}}>
                    {data ? (
                      <>
                        <Title>Loan Information </Title>
                        <br />
                        <Descriptions>
                          <Descriptions.Item label="Loan ID" span={1.5}>
                            <strong>{data?.loan_id}</strong>
                          </Descriptions.Item>
                        </Descriptions>
                        <Descriptions>
                          <Descriptions.Item label="Loan State">
                            <strong>
                              {data?.loan_state}
                            </strong>
                          </Descriptions.Item>
                        </Descriptions>
                        <Descriptions>
                          <Descriptions.Item label="Paid Amount">
                            <strong>
                              {data?.paid_amount} TK
                            </strong>
                          </Descriptions.Item>
                        </Descriptions>
                        
                        
                        <Descriptions>
                          <Descriptions.Item label="Total Amount">
                            <strong>
                              {data?.total_amount}{" "}
                            </strong>
                          </Descriptions.Item>
                        </Descriptions>
                      </>
                    ) : null}
                  </Card>
                  <br></br>
                </Col>
              </Row>
              
  
              
              
            </>
          ) : null}
          <br></br>
          {data?.transaction_list ?
          <>
          <Table
        columns={columns}
        dataSource={rows[0]}
        scroll={{ x: 1000 }}
        sticky
        pagination={true}
      />
       <Modal
        title='Transaction Details'
        visible={isModalVisible}
        cancelText="Close"
        okButtonProps={{ style: { display: 'none' } }}  
        onCancel={handleCancel}
        width={1200}
        >
        <TransactionDetails tID={transactionid} />
        
        </Modal>
          </>
          :null

          }

  
          {data?.logs ? (
            <>
              <Title>Logs </Title>
              <br />
              {data?.logs ? (
                <Timeline mode="left">
                  {data?.logs.map((item, index) => (
                    <Timeline.Item
                      label={moment
                        .utc(item?.created_at)
                        .format("YYYY-MM-DD hh:mm A")}
                      keys={index}
                    >
                       {item?.message} &nbsp;<br></br>
                      
                      {item?.admin?.name}({item?.admin?.user_id})
                    </Timeline.Item>
                  ))}
                </Timeline>
              ) : (
                <Empty />
              )}
            </>
          ) : null}
          <br />
        
      </>
    );
  
    return (
      <Content>
        {pageheader}
        <br></br>
        {detailsuser}
      </Content>
    );
  };
  
  export default connect(null, {
    getLoanDetails,ForPayLoan
  })(LoanDetails);
  