import { Layout } from "antd";
import "antd/dist/antd.css";
import { Content, Footer } from "antd/lib/layout/layout";
import React from "react";
import { connect } from "react-redux";
import ".././style.css";

import { css } from "@emotion/react";
import { useState } from "react";
import BarLoader from "react-spinners/BarLoader";

import Topbar from "./Topbar";
import Sidebar from "./sidebar";
import Switcher from "./switcher";

const override = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-color: red;
`;
const PageLayout = (props) => {
  let [loading, setLoading] = useState(true);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {props.loader.loading ? (
        <BarLoader
          color="#3ab569"
          loading={loading}
          css={override}
          width="100%"
          size="100%"
        />
      ) : null}
      {/* {console.log('loader',props.loader.loading)} */}
      <Topbar />
      <Layout className="site-layout">
        <Sidebar />
        <Layout className="site-layout">
          <Content style={{ margin: "0px", padding: "0px", boxShadow: "none" }}>
            <Switcher />
          </Content>
        </Layout>
      </Layout>
      <Footer style={{ textAlign: "center" }}>
        Copyright © 2021-2022 demo.com. All rights reserved.{" "}
      </Footer>
    </Layout>
  );
};
const mapStateToProps = (state) => {
  return {
    loader: state.loader,
  };
};
export default connect(mapStateToProps)(PageLayout);
